import { getState, signalStoreFeature, withMethods } from "@ngrx/signals";
import { EntityState } from "@ngrx/signals/entities";
import { SelectOption } from "../../ui/form/search-select/SelectOption.class";
import { TGetParametersByFunctionIdFilters } from "./functions.store.types";
import { ScenarioFunction, ScenarioFunctionParameter } from "../../api/models";
import { LanguageService } from "../../translate/language.service";


export function withFunctionsMethods() {
    return signalStoreFeature(
        withMethods((store: any) => ({
            getParametersByFunctionId(id: string, filter: TGetParametersByFunctionIdFilters = 'all'): SelectOption[] {
                let data: EntityState<ScenarioFunction> = getState<EntityState<ScenarioFunction>>(store);
                if (data.entityMap[id]) {
                    let parameters: ScenarioFunctionParameter[] = data.entityMap[id].parameters;
                    if (filter !== 'all') {
                        parameters = parameters.filter((parameter: ScenarioFunctionParameter) => (filter === 'requiredOnly' ? parameter.required : !parameter.required))
                    }
                    return parameters.map((parameter) => {
                        return new SelectOption({ id: parameter.id, label: LanguageService.translate(parameter.name), value: (parameter.enums || '*'), metadata: {
                            required: !!parameter.required
                        } });
                    })
                }
                return [];
            },
            getFunctionById(id: string): SelectOption | undefined {
                let data: EntityState<ScenarioFunction> = getState<EntityState<ScenarioFunction>>(store);
                if (data.entityMap[id]) {
                    return new SelectOption({ id: data.entityMap[id].id, label: LanguageService.translate(data.entityMap[id].name), value: data.entityMap[id].id, metadata: {
                        returnType: data.entityMap[id].type
                    }});
                }
                return;
            }
        })),
    )
}

